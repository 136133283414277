<template>
  <land-section
    id="about-contact"
    :color="backColor"
    :space="space"
  >
    <v-container>
      <v-row
        v-if="title || subtitle"
        justify="center"
      >
        <v-col
          cols="12"
        >
          <land-title
            v-if="title"
            :title="title"
            class="text-uppercase"
            space="1"
          />
          <land-subtitle
            v-if="subtitle"
            :title="subtitle"
            space="6"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(item, i) in contacts"
          :key="i"
          cols="12"
          md="6"
        >
          <div
            class="d-flex flex-column"
          >
            <v-divider
              class="my-1"
            />
            <v-card
              class="d-flex flex-row py-3"
              color="transparent"
              flat
              tile
            >
              <v-icon
                :color="color"
              >
                {{ item.icon }}
              </v-icon>
              <div
                class="text-subtitle-1 ml-4"
                :class="[`${color}--text`]"
              >
                {{ item.text }}
              </div>
            </v-card>
            <v-divider
              class="my-1"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </land-section>
</template>

<script>
  export default {
    name: 'SectionAboutContact',
    props: {
      title: {
        type: String,
        default: ''
      },
      subtitle: {
        type: String,
        default: ''
      },
      backColor: {
        type: String,
        default: 'transparent'
      },
      space: {
        type: [Number, String],
        default: 20
      }
    },
    data () {
      return {
        color: 'blue-grey',
        // contacts: [{
        //   icon: 'mdi-phone-in-talk-outline',
        //   text: '025-6589 4582'
        // }, {
        //   icon: 'mdi-email-outline',
        //   text: 'wyjzmdg@163.com'
        // }],
        contacts: [{
          icon: 'mdi-phone-in-talk-outline',
          text: '133 8208 1515（微信同号）'
        }, {
          icon: 'mdi-email-outline',
          text: 'product@landcoo.com'
        }, {
          icon: 'mdi-map-marker-outline',
          text: '南京市栖霞区石狮路22号绿地云都会A2栋613'
        }],
      }
    },
    created () {
    },
    methods: {}
  }
</script>
